import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '../config/config';

@Injectable({
  providedIn: 'root'
})
export class MeterService {

  public isMsnValid: boolean = false;
  public returnValue: string = '';

  private pathApi = this.config.setting['pathApi'] + "Meter/";

  constructor(private http: HttpClient,private config: AppConfig) { }

  validateMeter(ami: string, meterNumber: string, consumerType: number){

    this.returnValue = '';
    this.isMsnValid = /^\d+$/.test(meterNumber); // Check if only digits

    if (this.isMsnValid == false)
      this.returnValue = 'MSN should contains only digits';

    return this.returnValue;
  }

  getAllUnmetered(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}GetUnmetered`, obj);
  }
  getAllMeters(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}GetAllMeters`, obj);
  }
  GetFeeder33Meters(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}GetFeeder33Meters`, obj);
  }
  GetFeeder11Meters(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}GetFeeder11Meters`, obj);
  }
  GetDtMeters(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}GetDtMeters`, obj);
  }
  getConsumption(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}getConsumption`, obj);
  }
  getMeterForGraph(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}getMeterForGraph`, obj);
  }
  getMeterConsumption(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}getMeterConsumption`, obj);
  }
  getMeterDetails(id:number): Observable<any> {
    return this.http.get(`${this.pathApi}GetMeterDetailsOverview/${id}`);
  }
  uploadFile(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}uploadFile`, obj)
  }
  get(id: number): Observable<any> {
    return this.http.get(`${this.pathApi}${id}`);
  }
  getMeterTfeReport(id:number): Observable<any> {
    return this.http.get(`${this.pathApi}GetMeterTfeReport/${id}`);
  }
  GetCardsCount(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}GetCardsCount`, obj);
  }
  GetMetersCount(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}GetMetersCount`, obj);
  }
  GetUnmeteredCount(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}GetUnmeteredCount`, obj);
  }

  getDataForAutocomplete(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}getDataForAutocomplete`, obj)
  }
  GetMSNDataForAutocomplete(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}GetMSNDataForAutocomplete`, obj)
  }
  getTokenDataForAutocomplete(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}GetTokenDataForAutocomplete`, obj)
  }

  downloadMetersReport(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}DownloadMetersReport`, obj)
  }
  DownloadUnmeteredReport(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}DownloadUnmeteredReport`, obj)
  }

  download(obj: any): Observable<any> {
    return this.http.get(`${this.pathApi}downloadMeter/${obj}`);
  }

  loadToken(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}LoadToken`, obj);
  }
  save(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}save`, obj);
  }

  getTemplate(meterFileType: number): Observable<any> {
    return this.http.get(`${this.pathApi}getTemplate/${meterFileType}`);
  }

  getMeterTokenHistory(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}GetMeterTokenHistory`, obj);
  }

  getLastTokenLoaded(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}GetLastTokenLoaded`, obj);
  }

  getAllTriggers(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}GetAllTriggers`, obj);
  }

  getAllAmisWithMSN(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}GetAllAmisWithMSN`, obj);
  }

  getMeterTriggersCount(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}GetMeterTriggersCount`, obj);
  }

  updateTriggerStatus(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}UpdateTriggerStatus`, obj);
  }
  updateMeterStatus(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}UpdateMeterStatus`, obj);
  }
  getAllDeviceManagement(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}getAllDeviceManagement`, obj);
  }

  setMeterStatusConnected(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}setMeterStatusConnected`, obj);
  }
  getAllMeterEvents(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}getAllMeterEvents`, obj);
  }

  downloadMeterTriggers(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}DownloadMeterTriggers`,obj);
  }

  loadRemoteToken(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}loadRemoteToken`, obj);
  }

  getMeterIdFromMSNAndAmi(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}getMeterIdFromMSNAndAmi`, obj);
  }
  getMeterDetailsByMSN(msn: any): Observable<any> {
    return this.http.get(`${this.pathApi}getMeterDetailsByMSN/${msn}`);
  }

  getMeterTfeForGraph(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}getMeterTfeForGraph`, obj);
  }
  uploadFileForMeterReads(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}uploadFileForMeterReads`, obj)
  }
}

export enum TypeOfConsumer{
    Account = 1,
    Dt = 2,
    Feeder11 = 3,
    Feeder33 = 4
}
