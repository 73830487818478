<main class="container-fluid">
    <div class="container-wrapper">
        <span class="field-operation-title">Asset Performance</span>
        <div class="menu-tab-filter">
          <app-tab-default [firstText]="'33KV Feeder'" [secondText]="'11KV Feeder'" [thirdText]="'DT'"
                           (tabChanged)="tabChanged($event)" [tabSelected]="tabMenu"></app-tab-default>
        </div>

        <div class="filters-container">
            <div class="multi-filter-wrapper" [ngClass]="{'show-filter' : showFilter}">

                <app-multi-filter *ngIf="filterApplied"
                    [data]="allFilters"
                    (dataChange)="setFilters($event)"
                    [(showFilter)]="showFilter"
                    (dropdownChange)="businessUnitSelected($event)"
                >
                </app-multi-filter>

                <app-range-datepicker
                    [fromDate]="dateFrom"
                    [toDate]="dateTo"
                    [name]="'Period'"
                    (dateRangeSelected)="dateChange($event)">
                </app-range-datepicker>

            <app-search-autocomplete
                #search
                [placeholder]="searchPlaceholder"
                [width]="25"
                [backgroundColor]="'transparent'"
                [borderColor]="'#E8E8E8'"
                [(searchText)]="searchText"
                [options]="options"
                (searchChange)="inputChanged($event)"
                (searchAutocompleteChange)="getOptions($event)"
            >
            </app-search-autocomplete>
        </div>
        <div class="download-button">
            <app-download-button-new
              [isOnlyDownload]="true"
              (downloadButtonClickedChange)="download()">
            </app-download-button-new>
        </div>
      </div>
        <div *ngIf="tabMenu==3">
            <app-asset-performance-dt-new
            [buName]="buName"
            [utName]="utName"
            [assetPerformanceDts]="assetPerformanceDts"
            [checkedList]="checkedList"
            ></app-asset-performance-dt-new>
        </div>
        <div *ngIf="tabMenu==2">
            <app-asset-performance-f11-new
            [buName]="buName"
            [assetPerformanceF11]="assetPerformanceF11"
            [checkedList]="checkedList"
            ></app-asset-performance-f11-new>
        </div>
        <div *ngIf="tabMenu==1">
            <app-asset-performance-f33-new
            [assetPerformanceF33]="assetPerformanceF33"
            [checkedList]="checkedList"
            ></app-asset-performance-f33-new>
        </div>
        <div class="pagination-wrapper">
            <app-fo-pagination-new [lastPageSize]="lastPageSize" [pageSize]="pageSize" [count]="count"
                [currentPage]="currentPage" (pageChange)="pageChange($event)">
            </app-fo-pagination-new>
        </div>
    </div>
</main>
