<main>
    <app-import-details-popup
        (confirmUpload)="uploadFile()"
        (confirmGetTemplate)="downloadTemplate()"
        [placeholder]="'Import multiple meter reads (Supported format XLSX).'"
    ></app-import-details-popup>
    <div class="feeders-profile">
        <div class="container-wrapper">

            <div class="feeders-header">
                <div class="title-container">
                    <div class="title-u">Feeders</div>
                    <div class="subtitle">Read feeder reads</div>
                </div>
            </div>

            <div class="menu-tab-filter">
                <app-tab-default [firstText]="'33KV Feeder'"
                    [secondText]="'11KV Feeder'"
                    (tabChanged)="tabChanged($event)"
                    [tabSelected]="tabMenu"
                ></app-tab-default>

                <button class="btn btn-outline btn-upload" (click)="openTabUpload()">
                    <span class="text">Upload File</span>
                </button> 
            </div>

            <div class="filters-container">
                <div class="multi-filter-wrapper">
                    <app-range-datepicker
                        [fromDate]="dateFrom"
                        [toDate]="dateTo"
                        [name]="'Period'"
                        [maxDate]="maxDate"
                        (dateRangeSelected)="updateDate($event)">
                    </app-range-datepicker>

                    <app-search-autocomplete
                        #search
                        [width]="30"
                        [placeholder]="searchPlaceholder"
                        [searchText]="searchText"
                        [options]="options"
                        (searchChange)="searchChange($event)"
                        (searchAutocompleteChange)="getOptions($event)"
                    ></app-search-autocomplete>
                </div>
            </div>

            <table class="standard-table" *ngIf="this.tabMenu == 2">
                <thead>
                    <tr>
                        <th class="large-td"><span title="Feeder Name">FEEDER NAME</span></th>
                        <th><span title="Meter S/N">METER S/N</span></th>
                        <th><span title="Received Energy (MWh)">RECEIVED ENERGY(MWH)</span></th>
                        <th><span title="Accounted Energy (MWh)">ACCOUNTED ENERGY(MWH)</span></th>
                        <th><span title="% Losses">% LOSSES</span></th>
                    </tr>
                </thead>
                <tbody class="table-body">
                    <tr *ngFor="let item of f11ReadsData">
                        <td class="large-td">
                            <span *ngIf="item.feederName !== '/'" title="{{item.feederName}}">{{item.feederName | hasValue}}</span>
                            <span *ngIf="item.feederName === '/'" class="NaN">N/A</span>
                        </td>
                        <td>
                            <span *ngIf="item.msn !== '/'" title="{{item.msn}}">{{item.msn | hasValue}}
                                <img alt="Copy" class="icons" src="assets/images-new-design/copy.png"(click)="copyToClipboard(item.msn)">
                            </span>
                            <span *ngIf="item.msn === '/'" class="NaN">N/A</span>
                        </td>
                        <td>
                            <span *ngIf="item.energyReceived !== '/'" title="{{item.energyReceived}}">{{item.energyReceived | hasValue}}</span>
                            <span *ngIf="item.energyReceived === '/'" class="NaN">N/A</span>
                        </td>
                        <td>
                            <span *ngIf="item.energyAccounted !== '/'" title="{{item.energyAccounted}}">{{item.energyAccounted | hasValue}}</span>
                            <span *ngIf="item.energyAccounted === '/'" class="NaN">N/A</span>
                        </td>
                        <td>
                            <span *ngIf="item.lossesPercentage !== '/'" title="{{item.lossesPercentage}}">{{item.lossesPercentage | hasValue}}</span>
                            <span *ngIf="item.lossesPercentage === '/'" class="NaN">N/A</span>
                        </td>
                    </tr>
                </tbody>
            </table>

            <table class="standard-table" *ngIf="this.tabMenu == 1">
                <thead>
                    <tr>
                        <th class="large-td"><span title="Feeder Name">FEEDER NAME</span></th>
                        <th><span title="Meter S/N">METER S/N</span></th>
                        <th><span title="Received Energy (MWh)">RECEIVED ENERGY(MWH)</span></th>
                        <th><span title="Accounted Energy (MWh)">ACCOUNTED ENERGY(MWH)</span></th>
                        <th><span title="% Losses">% LOSSES</span></th>
                    </tr>
                </thead>
                <tbody class="table-body">
                    <tr *ngFor="let item of f33ReadsData">
                        <td class="large-td">
                            <span *ngIf="item.feederName !== '/'" title="{{item.feederName}}">{{item.feederName | hasValue}}</span>
                            <span *ngIf="item.feederName === '/'" class="NaN">N/A</span>
                        </td>
                        <td>
                            <span *ngIf="item.msn !== '/'" title="{{item.msn}}">{{item.msn | hasValue}}
                                <img alt="Copy" class="icons" src="assets/images-new-design/copy.png"(click)="copyToClipboard(item.msn)">
                            </span>
                            <span *ngIf="item.msn === '/'" class="NaN">N/A</span>
                        </td>
                        <td>
                            <span *ngIf="item.energyReceived !== '/'" title="{{item.energyReceived}}">{{item.energyReceived | hasValue}}</span>
                            <span *ngIf="item.energyReceived === '/'" class="NaN">N/A</span>
                        </td>
                        <td>
                            <span *ngIf="item.energyAccounted !== '/'" title="{{item.energyAccounted}}">{{item.energyAccounted | hasValue}}</span>
                            <span *ngIf="item.energyAccounted === '/'" class="NaN">N/A</span>
                        </td>
                        <td>
                            <span *ngIf="item.lossesPercentage !== '/'" title="{{item.lossesPercentage}}">{{item.lossesPercentage | hasValue}}</span>
                            <span *ngIf="item.lossesPercentage === '/'" class="NaN">N/A</span>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div class="pagination-wrapper">
                <app-pagination [lastPageSize]="lastPageSize" [pageSize]="pageSize" [currentPage]="currentPage" [count]="count" (pageChange)="pageChange($event)">
                </app-pagination>
            </div>
        </div>
    </div>
</main>
