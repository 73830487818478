import { Component, OnInit, ViewChild } from '@angular/core';
import { SearchAutocompleteComponent } from '../../common-new-design/search-autocomplete/search-autocomplete.component';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';
import { cloneDeep } from 'lodash';
import { transformToString } from 'src/app/helpers/dateUtils';
import { ToastrService } from 'ngx-toastr';
import { Feeder11Service } from 'src/app/services/feeder11.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Feeder33Service } from 'src/app/services/feeder33.service';
import { ImportDetailsPopupComponent } from '../../common-new-design/import-details-popup/import-details-popup.component';
import { MeterService } from 'src/app/services/meter.service';

@Component({
  selector: 'app-feeder-reads-settings',
  templateUrl: './feeder-reads-settings.component.html',
  styleUrl: './feeder-reads-settings.component.scss'
})
export class FeederReadsSettingsComponent implements OnInit {

  public tabMenu: number = 1

  public currentPage: number = 1
  public pageSize: number = 10
  public count: number = 0;
  public lastPageSize: number = 1

  public dateFrom: any = '';
  public dateTo: any = '';
  public dtFrom: Date = new Date();
  public dtTo: Date = new Date();
  public maxDate: Date = new Date();

  @ViewChild('search') searchChild!: SearchAutocompleteComponent
  public searchPlaceholder: string = "Search by MSN or Feeder name";
  public searchText: SearchAutocomplete = new SearchAutocomplete();
  public searchLength: number = 1
  public options = [];

  public f11ReadsData: any[] = [];
  public f33ReadsData: any[] = [];

  @ViewChild(ImportDetailsPopupComponent) importRef!: ImportDetailsPopupComponent;
  public fileChosen: any = null;
  public file: any = null;

  constructor(private toastr: ToastrService,
    private f11Service: Feeder11Service,
    private f33Service: Feeder33Service,
    private meterService: MeterService
  ){}

  ngOnInit(): void {
    this.maxDate.setDate(this.maxDate.getDate() - 1);
    this.setMainDate()
    this.reloadTable()
  }

  reloadTable(){
    let obj = {
      pageInfo: {
        pageSize: this.pageSize,
        page: this.currentPage,
      },
      filterParams: {
        dateFrom: transformToString(this.dateFrom),
        dateTo: transformToString(this.dateTo),
        searchContent: this.searchText ? this.searchText.name : this.searchText,
      }
    }
    if (this.tabMenu == 1) {
      this.f33Service.getF33Reads(obj).subscribe(response => {
        if (response.status === 200) {
          this.f33ReadsData = response.data.data;
          if (obj.pageInfo.page == 1) {
            this.count = response.data.count;
            this.lastPageSize = Math.ceil(this.count / this.pageSize);
          }
        } else {
          this.toastr.error(response.message);
        }
      }, _ => {
        this.toastr.error('An error occurred.');
      })
    }
    else if (this.tabMenu == 2) {
      this.f11Service.getF11Reads(obj).subscribe(response => {
        if (response.status === 200) {
          this.f11ReadsData = response.data.data;
          if (obj.pageInfo.page == 1) {
            this.count = response.data.count;
            this.lastPageSize = Math.ceil(this.count / this.pageSize);
          }
        } else {
          this.toastr.error(response.message);
        }
      }, _ => {
        this.toastr.error('An error occurred.');
      })
    }
    else {
      this.count = 0;
    }
  }

  setMainDate() {
    let dte = new Date();
    dte.setHours(2,0,0);
    this.dtFrom.setDate(dte.getMonth());
    this.dtTo.setDate(dte.getDate() - 1);
    this.dateFrom = {year: this.dtFrom.getFullYear(), month: this.dtFrom.getMonth() + 1, day: 1};
    this.dateTo = {year: this.dtTo.getFullYear(), month: this.dtTo.getMonth() + 1, day: this.dtTo.getDate()};
  }

  tabChanged(value: number){
    this.tabMenu = value
    this.searchText.name = "";
    
    this.searchText = new SearchAutocomplete()
    if (this.searchChild)
    {
      this.searchChild.searchText = new SearchAutocomplete()
      this.searchChild.resetOptions()
    }

    this.setMainDate()
    this.reloadTable()
  }

  updateDate(date: any) {
    this.dateFrom = date.dateFrom;
    this.dateTo = date.dateTo;
    this.reloadTable()
  }

  searchChange(text: SearchAutocomplete) { 
    this.searchText = text;
    this.searchText = cloneDeep(this.searchText);
    this.currentPage = 1;
    this.reloadTable();
  }

  getOptions(searchText: any) { 
    let obj = {
      searchText: searchText.name, 
      msnIncluded: true
    }
    this.searchChild.searchLength = this.searchLength;
    if (searchText.name.length > 2) {
      if (this.tabMenu == 1) {
        this.f33Service.getDataForAutocomplete(obj)
        .subscribe({ 
          next : (response) => this.options = response.data,
          error: (err: HttpErrorResponse) => this.toastr.error("An error, please contact support center"),
        })
      }
      else if (this.tabMenu == 2) {
        this.f11Service.getDataForAutocomplete(obj)
        .subscribe({ 
          next : (response) => this.options = response.data,
          error: (err: HttpErrorResponse) => this.toastr.error("An error, please contact support center"),
        })
      }
    } else {
      this.searchChild.resetOptions()
    }
  }

  pageChange(value: any) {
    this.currentPage = value
    this.reloadTable()
  }

  copyToClipboard(text: string) {
    navigator.clipboard.writeText(text)
    this.toastr.success('Copied to clipboard')
  }

  openTabUpload() {
    this.importRef.changeState();
  }

  isFileChosen() {
    if (this.fileChosen)
      return true;

    return false;
  }

  uploadFile() {
    this.file = this.importRef.fileChosen;
    const formData = new FormData();
    formData.append("uploadFile", this.file);
    formData.append("update", this.importRef.update.toString());
    this.meterService.uploadFileForMeterReads(formData).subscribe(response => {
      if (response.status === 200) {
        this.toastr.success(response.message);
      } else {
        this.toastr.error(response.message);
        if (response.data && response.data.length > 0)
          window.location.href = response.data;
      }
      this.fileChosen = null;
    }, error => {
      this.toastr.error('An error occurred.');
      this.fileChosen = null;
    });
  }

  downloadTemplate(){
    this.toastr.warning("Not implemented yet");
  }
}
